<script setup lang="ts">
import { useToast } from 'vue-toastification'
import ModalChangeOfferStatus from '~/components/offer/modals/ModalChangeOfferStatus.vue'
import ModalCloseOffer from '~/components/offer/modals/ModalCloseOffer.vue'
import ModalRemoveOffer from '~/components/offer/modals/ModalRemoveOffer.vue'
import ModalThankForFeedback from '~/components/offer/modals/ModalThankForFeedback.vue'
import ModalThankForHiring from '~/components/offer/modals/ModalThankForHiring.vue'
import { useOffersStore } from '~/store/offers'
import { INTEGRATE_FLOW_PLACEMENT } from '~/config/featureFlags'

const props = defineProps({
	class: {
		type: [String, Array, Object],
		default: '',
	},
	offer: {
		type: Object,
		default: () => ({}),
	},
	mode: {
		type: String,
		default: 'menu',
	},
})

const { $auth, $repository, $tracking, $hasFeature, $config } = useNuxtApp()
const { updateChangeOfferStatus, deleteOffer, toggleOfferNotification } = useOffersStore()
const { t: $t } = useI18n()
const localePath = useLocalePath()
const toast = useToast()
const route = useRoute()
const router = useRouter()
const dropdownOpen = ref(false)
const loadingChangeStatus = ref(false)
const showModalChangeOfferStatus = ref(false)
const loadingRemoveOffer = ref(false)
const showModalRemoveOffer = ref(false)
const loadingCloseOffer = ref(false)
const showModalCloseOffer = ref(false)
const statusToChange = ref(0)
const loadingLoadOption = ref(false)
const showModalThankForHiring = ref(false)
const showModalThankForFeedback = ref(false)
const emit = defineEmits(['close-offer'])

interface Option {
	type: string
	icon: string
	label: string
	action?: () => void
	to?: string
}

const options = computed<Option[]>(() => {
	return [
		{
			type: 'detail',
			icon: 'i-ic-baseline-remove-red-eye',
			label: $t('Ver detalle'),
			to: localePath(`/offers/${props.offer.id}/details`),
		},
		...(props.offer.public_path
			? [
					{
						type: 'public-url',
						icon: 'i-material-symbols-open-in-new',
						label: $t('Ver oferta pública'),
						to: `${$config.public.domain}/oferta/${props.offer.public_path}`,
						target: '_blank',
					},
				]
			: []),
		{
			type: 'activate',
			icon: 'i-ic-baseline-play-arrow',
			label: $t('Activar'),
			click: () => {
				statusToChange.value = ID_OFFER_ACTIVE
				showModalChangeOfferStatus.value = true
			},
		},
		{
			type: 'edit',
			icon: 'i-ic-baseline-edit',
			label: $t('Editar'),
			to: localePath(`/offers/${props.offer.id}/edit`),
		},
		{
			type: 'pause',
			icon: 'i-ic-baseline-pause',
			label: $t('Pausar'),
			click: () => {
				statusToChange.value = ID_OFFER_PAUSE
				showModalChangeOfferStatus.value = true
			},
		},
		{
			type: 'finish',
			icon: 'i-ic-baseline-stop',
			label: $t('Finalizar'),
			click: () => {
				if ($hasFeature(INTEGRATE_FLOW_PLACEMENT)) {
					showModalCloseOffer.value = true
					return
				}
				statusToChange.value = ID_OFFER_CLOSE
				showModalChangeOfferStatus.value = true
			},
		},
		{
			type: 'delete',
			icon: 'i-ic-baseline-delete',
			label: $t('Borrar'),
			click: () => {
				showModalRemoveOffer.value = true
			},
		},
		{
			type: 'notification',
			icon: props.offer.notification_preferences_is_active
				? 'i-ic-baseline-notifications-active'
				: 'i-ic-baseline-notifications-off',
			label: props.offer.notification_preferences_is_active
				? $t('Notificaciones activas')
				: $t('Notificaciones inactivas'),
			click: () => {
				toggleNotification()
			},
			slot: 'notification',
		},
		{
			type: 'previous',
			icon: 'i-material-symbols-settings-backup-restore',
			label: $t('Versión anterior'),
			click: () => {
				changeOldVersion()
			},
		},
	]
})

const getOptionsBySlugs = computed(() => {
	return (slugs: string[]) => options.value.filter((option) => slugs.includes(option.type))
})

const getOptions = computed(() => {
	const statusOptions = {
		[ID_OFFER_DRAFT]: ['public-url', 'edit', 'delete'],
		[ID_OFFER_DRAFT_HIRE]: ['public-url', 'edit', 'delete'],
		[ID_OFFER_ACTIVE]: ['public-url', 'pause', 'finish', 'notification'],
		[ID_OFFER_PAUSE]: ['public-url', 'activate', 'finish'],
		[ID_OFFER_PENDING_TO_APPROVED]: ['public-url', 'edit', 'finish'],
		[ID_OFFER_REJECTED]: ['public-url', 'edit', 'delete'],
	}

	const allOptions = statusOptions[props.offer.work_offer_status?.id || props.offer.status_id] || []

	if (props.mode === 'inbox') {
		allOptions.unshift('detail')
		allOptions.push('previous')
	}

	return getOptionsBySlugs.value(allOptions)
})

const changeStatusOffer = async () => {
	loadingChangeStatus.value = true
	const response = await updateChangeOfferStatus(props.offer.id, { work_offer_status_id: statusToChange.value })

	if (!response.success) {
		toast.error($t('Hubo un problema, inténtelo nuevamente.'))
	}

	loadingChangeStatus.value = false
	showModalChangeOfferStatus.value = false
}

const removeOffer = async () => {
	loadingRemoveOffer.value = true
	const response = await deleteOffer(props.offer.id)

	if (!response.success) {
		toast.error($t('Hubo un problema, inténtelo nuevamente.'))
	}

	loadingRemoveOffer.value = false
	showModalRemoveOffer.value = false
}

const closeOffer = async (reasonId, hiredCandidates) => {
	loadingCloseOffer.value = true
	const response = await updateChangeOfferStatus(props.offer.id, {
		work_offer_status_id: ID_OFFER_CLOSE,
		reason_to_close_id: reasonId,
		hired_match_user_ids: hiredCandidates,
	})

	if (!response.success) {
		loadingCloseOffer.value = false
		toast.error(response.message)
		return
	}

	loadingCloseOffer.value = false
	showModalCloseOffer.value = false
	emit('close-offer')

	if (hiredCandidates.length > 0) {
		showModalThankForHiring.value = true
		return
	}

	showModalThankForFeedback.value = true
}

const toggleNotification = async () => {
	if (loadingLoadOption.value) return

	const params = {
		is_active: !props.offer.notification_preferences_is_active,
	}

	toast.success(params.is_active ? $t('Notificaciones activadas') : $t('Notificaciones desactivadas'))

	const response = await toggleOfferNotification(props.offer.id, params)

	if (!response.success) {
		toast.error($t('Hubo un problema, inténtelo nuevamente.'))
	}
}

const changeOldVersion = async () => {
	loadingLoadOption.value = true
	const { success } = await $repository.v4.hire.profile.postSavePreferences({
		preferences: [
			{
				preference_category_id: 1,
				is_active: false,
			},
		],
	})

	if (!success) {
		loadingLoadOption.value = false
		return
	}

	const query = { ...route.query }
	delete query.candidate_id

	await router.replace({ query })
	await $auth.fetchMe()

	$tracking.offer.newWorkflowActivate()

	loadingLoadOption.value = false
}
</script>

<template>
	<UDropdown
		v-if="getOptions.length > 0"
		v-model:show="dropdownOpen"
		:items="[getOptions]"
		:close-delay="1000"
		:ui="{ width: 'w-56' }"
		:class="['', props.class]"
	>
		<UButton
			v-if="props.mode === 'menu'"
			:loading="loadingLoadOption"
			variant="ghost"
			color="white"
			square
			size="md"
			class="-m-1 group-hover:ring-1 ring-gray-300 group-hover:shadow"
			icon="i-ic-baseline-more-vert"
		>
		</UButton>
		<UButton
			v-if="props.mode === 'inbox'"
			:loading="loadingLoadOption"
			color="white"
			size="md"
			class="pl-3 group-hover:ring-1 ring-gray-300 group-hover:shadow"
			icon="i-ic-baseline-more-vert"
			trailing
		>
			{{ $t('Opciones') }}
		</UButton>

		<template #notification="{ item }">
			<span
				class="flex items-center p-1.5 -m-1.5 w-full"
				:class="{ 'opacity-50 cursor-not-allowed': loadingLoadOption }"
				@click.stop="item.click()"
			>
				<UIcon
					:name="item.icon"
					class="w-4.5 h-4.5 text-gray-400 hover:text-gray-600"
				/>

				<span class="pl-2 flex-1 flex items-center justify-between"> {{ item.label }} </span>
			</span>
		</template>
	</UDropdown>

	<ModalChangeOfferStatus
		v-model="showModalChangeOfferStatus"
		:status-id="statusToChange"
		:ok-loading="loadingChangeStatus"
		@confirm="changeStatusOffer"
	/>

	<ModalCloseOffer
		v-if="showModalCloseOffer"
		v-model="showModalCloseOffer"
		:ok-loading="loadingCloseOffer"
		:offer="props.offer"
		@confirm="closeOffer"
	/>

	<ModalThankForFeedback
		v-model="showModalThankForFeedback"
		@close="showModalThankForFeedback = false"
	/>

	<ModalThankForHiring
		v-model="showModalThankForHiring"
		:offer-id="props.offer?.id"
		@close="showModalThankForHiring = false"
	/>

	<ModalRemoveOffer
		v-model="showModalRemoveOffer"
		:ok-loading="loadingRemoveOffer"
		@confirm="removeOffer"
	/>
</template>

<style scoped></style>
